import React, { Component } from 'react';
import { StandardPageProps } from '../utils/Props';
import Layout from '../components/layout/Layout';
import SEO from '../components/layout/SEO';
import {
  CombinedChannelDetails,
  ContentClient,
} from '../services/ContentClient';
import { StaticImage } from 'gatsby-plugin-image';
import ReactConfetti from 'react-confetti';
import {
  SiTwitch,
  SiTwitter,
  SiYoutube,
  SiPatreon,
  SiInstagram,
  SiTiktok,
} from 'react-icons/si';
import { HiOutlineShoppingBag } from 'react-icons/hi';
import classNames from 'classnames';
import {
  DuringAnimationFrame,
  IsBrowserEnvironment,
} from '../utils/BrowserHelpers';

// @ts-ignore
import JaWoodleLogoType from '../assets/images/jawoodle-logotype.inline.svg';
// @ts-ignore
import JWLick from '../assets/images/jwlick.gif';

const konamiCode = [
  'ArrowUp',
  'ArrowUp',
  'ArrowDown',
  'ArrowDown',
  'ArrowLeft',
  'ArrowRight',
  'ArrowLeft',
  'ArrowRight',
  'KeyB',
  'KeyA',
];

export default class IndexPage extends Component<
  IndexPageProps,
  IndexPageState
> {
  private konamiCodePos = 0;

  constructor(props: Readonly<IndexPageProps> | IndexPageProps) {
    super(props);
    this.state = {
      loadingChannelDetails: true,
      channelDetails: undefined,
      easterEgg: false,
      windowWidth: 0,
      windowHeight: 0,
    };
    this.onWindowResize = DuringAnimationFrame(this.onWindowResize);
  }

  componentDidMount() {
    const client = new ContentClient();

    client.fetchChannelDetails(false).then(
      (details) => {
        this.setState({
          loadingChannelDetails: false,
          channelDetails: details,
        });
      },
      () => {
        this.setState({
          loadingChannelDetails: false,
        });
      }
    );

    if (IsBrowserEnvironment) {
      window.addEventListener('resize', this.onWindowResize);
      document.body.addEventListener('keydown', this.onBodyKeyDown);
      this.onWindowResize();
    }
  }

  componentWillUnmount() {
    if (IsBrowserEnvironment) {
      window.removeEventListener('resize', this.onWindowResize);
      document.body.removeEventListener('keydown', this.onBodyKeyDown);
    }
  }

  render() {
    const {
      loadingChannelDetails,
      channelDetails,
      easterEgg,
      windowWidth,
      windowHeight,
    } = this.state;

    const baseLinkClassNames = classNames(
      'flex justify-center items-center rounded-md relative',
      'text-gray-50',
      'transition opacity-70 border-opacity-50',
      'border border-gray-50',
      'outline-none ring-offset-4 ring-gray-50 ring-opacity-50 ring-offset-brand-jawoodle-dark',
      'focus:ring hover:opacity-100 focus:opacity-100'
    );

    const socialLinksClassNames = classNames(
      baseLinkClassNames,
      'w-12 h-12 md:w-16 md:h-16 lg:w-24 lg:h-24'
    );
    const socialLinksIconClassNames = 'w-6 h-6 md:w-8 md:h-8 lg:w-12 lg:h-12';

    const textLinkClassNames = classNames(
      baseLinkClassNames,
      'w-28 h-12 md:w-38 md:h-16 lg:w-54 lg:h-24'
    );

    const buttonSpacing = 'px-1 py-1 md:px-3 md:py-3';

    const isTwitchLive =
      !loadingChannelDetails && !!channelDetails?.twitch.stream;

    return (
      <Layout location={this.props.location}>
        <SEO
          title='JaWoodle – Gamer, YouTuber and Twitch Streamer'
          titleTemplate='{pageTitle}'
          description='The channel of a super energetic and enthusiastic Australian man playing games and trying to make you laugh!'
          slug='/'
        />
        {easterEgg ? (
          <div
            className={
              'fixed inset-0 pointer-events-none overflow-hidden w-full h-full'
            }
          >
            <ReactConfetti width={windowWidth} height={windowHeight} />
          </div>
        ) : undefined}
        <div className='flex flex-col justify-center items-center w-full max-w-4xl'>
          <div className='flex-none w-auto -mb-16 px-12 flex items-center'>
            {easterEgg ? (
              <img src={JWLick} alt='JaWoodle' />
            ) : (
              <StaticImage
                placeholder='none'
                width={128}
                layout='fixed'
                src='../assets/images/jawoodle-avatar-white-stroke.png'
                alt='JaWoodle'
              />
            )}

            <h1 className='hidden md:block ml-6'>
              <JaWoodleLogoType className='w-96' />
              <span className='sr-only'>JaWoodle</span>
            </h1>
          </div>
          <div className='pt-20 md:pt-12'>
            <ul className='flex w-full flex-wrap justify-center'>
              <li className={buttonSpacing}>
                <a
                  href='https://www.youtube.com/c/jawoodle'
                  target='_blank'
                  rel='noopener'
                  className={socialLinksClassNames}
                  title='YouTube'
                  aria-label='YouTube'
                >
                  <SiYoutube className={socialLinksIconClassNames} />
                </a>
              </li>
              <li className={buttonSpacing}>
                <a
                  href='https://www.twitch.tv/jawoodle'
                  target='_blank'
                  rel='noopener'
                  className={socialLinksClassNames}
                  title='Twitch'
                  aria-label='Twitch'
                >
                  {isTwitchLive ? (
                    <div
                      className='absolute flex h-3 w-3 md:w-4 md:h-4 lg:w-6 lg:h-6 top-0 right-0 pointer-events-none transform translate-x-1/2 -translate-y-1/2 z-10'
                      role='presentation'
                    >
                      <span className='animate-ping absolute block h-full w-full rounded-full bg-red-400 opacity-75' />
                      <span className='absolute block h-full w-full rounded-full bg-red-400' />
                    </div>
                  ) : undefined}

                  <SiTwitch className={socialLinksIconClassNames} />
                </a>
              </li>
              <li className={buttonSpacing}>
                <a
                  href='https://twitter.com/jawoodle'
                  target='_blank'
                  rel='noopener'
                  className={socialLinksClassNames}
                  title='Twitter'
                  aria-label='Twitter'
                >
                  <SiTwitter className={socialLinksIconClassNames} />
                </a>
              </li>
              <li className={buttonSpacing}>
                <a
                  href='https://www.instagram.com/jawoodle'
                  target='_blank'
                  rel='noopener'
                  className={socialLinksClassNames}
                  title='Instagram'
                  aria-label='Instagram'
                >
                  <SiInstagram className={socialLinksIconClassNames} />
                </a>
              </li>
              <li className={buttonSpacing}>
                <a
                  href='https://tiktok.com/@jawoodle'
                  target='_blank'
                  rel='noopener'
                  className={socialLinksClassNames}
                  title='Tiktok'
                  aria-label='Tiktok'
                >
                  <SiTiktok className={socialLinksIconClassNames} />
                </a>
              </li>
            </ul>
            <ul className='flex w-full flex-wrap justify-center'>
              <li className={buttonSpacing}>
                <a
                  href='https://www.patreon.com/jawoodle'
                  target='_blank'
                  rel='noopener'
                  className={textLinkClassNames}
                  title='Patreon'
                  aria-label='Patreon'
                >
                  <SiPatreon className={socialLinksIconClassNames} />
                  <div className='ml-3 flex flex-col'>
                    <span className='hidden lg:block'>Support on</span>
                    <span className='font-bold'>Patreon</span>
                  </div>
                </a>
              </li>
              <li className={buttonSpacing}>
                <a
                  href='https://jawoodle-merch.creator-spring.com/'
                  target='_blank'
                  rel='noopener'
                  className={textLinkClassNames}
                  title='Merch'
                  aria-label='Merch'
                >
                  <HiOutlineShoppingBag className={socialLinksIconClassNames} />
                  <div className='ml-3 flex flex-col'>
                    <span className='hidden lg:block'>Get sweet</span>
                    <span className='font-bold'>
                      Merch<span className='hidden lg:inline'>andise</span>
                    </span>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </Layout>
    );
  }

  onBodyKeyDown = (e: KeyboardEvent) => {
    if (this.state.easterEgg) {
      return;
    }

    const expectedKey = konamiCode[this.konamiCodePos];

    if (e.code === expectedKey) {
      this.konamiCodePos += 1;

      if (this.konamiCodePos === konamiCode.length) {
        this.setState({
          easterEgg: true,
        });
      }
    } else {
      this.konamiCodePos = 0;
    }
  };

  onWindowResize = () => {
    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    });
  };
}

interface IndexPageProps extends StandardPageProps {}

interface IndexPageState {
  loadingChannelDetails: boolean;
  channelDetails?: CombinedChannelDetails;
  easterEgg: boolean;
  windowWidth: number;
  windowHeight: number;
}
